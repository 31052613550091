import {GET_MAP, POST_MAP} from './types'
import {useMutation, useQueryClient, useSuspenseQuery} from '@tanstack/react-query'

const jsonReviver = (_key: unknown, value: unknown) => {
    if (value instanceof Object) {
        if ('$decimal' in value && typeof value['$decimal'] === 'string') {
            return value['$decimal']
        }
    }
    return value
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function jsonReplacer(this: any, key: unknown, value: unknown) {
    const rawValue: unknown = this instanceof Object && typeof key === 'string' ? this[key] : value
    return rawValue
}

export class APIError extends Error {
    status: number
    statusText: string
    constructor(response: Response) {
        super(`http_status=${response.status} fetching ${response.url}`)
        this.status = response.status
        this.statusText = response.statusText
    }
}

const processRequest = async (request: Request, options?: Partial<RequestInit>) => {
    const headers = new Headers([
        ['Access-Control-Allow-Origin', '*'],
        ['Access-Control-Allow-Methods', 'GET,POST'],
    ])
    for (const [key, value] of request.headers.entries()) {
        headers.append(key, value)
    }

    const response = await fetch(request, {
        credentials: 'include',
        headers,
        ...options,
    })
    if (!response.ok) {
        throw new APIError(response)
    }
    const text = await response.text()
    const json = JSON.parse(text, jsonReviver)

    return json
}

export async function get<T extends keyof GET_MAP>(
    _endpoint: T,
    path: string,
    payload: GET_MAP[T]['request'] extends undefined ? undefined : GET_MAP[T]['request'],
): Promise<GET_MAP[T]['response']> {
    const url = new URL(`${_GREENCHIP_API_HOSTNAME_}${path}`, window.location.href)

    const params = payload as {[k: string]: unknown} | undefined
    if (params) {
        Object.keys(params).forEach(k => {
            if (params[k] === undefined) {
                return
            } else if (typeof params[k] === 'boolean') {
                url.searchParams.append(k, params[k] ? 'true' : '')
            } else {
                url.searchParams.append(k, String(jsonReplacer(undefined, params[k])))
            }
        })
    }

    return processRequest(
        new Request(url, {
            method: 'GET',
        }),
    )
}

export async function post<T extends keyof POST_MAP>(
    _endpoint: T,
    path: string,
    body: POST_MAP[T]['request'],
): Promise<POST_MAP[T]['response']> {
    const url = new URL(`${_GREENCHIP_API_HOSTNAME_}${path}`, window.location.href)

    return processRequest(
        new Request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body, jsonReplacer),
        }),
    )
}

function generateQueryKey<T extends keyof GET_MAP>(
    endpoint: T,
    params: GET_MAP[T]['request'] | undefined,
) {
    const queryKey: unknown[] = [endpoint]
    if (params) {
        for (const key in params) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            queryKey.push(jsonReplacer(undefined, (params as any)[key]))
        }
    }
    return queryKey
}

export function useGet<T extends keyof GET_MAP>({
    endpoint,
    payload,
}: {
    endpoint: T
    payload?: GET_MAP[T]['request'] extends undefined ? undefined : GET_MAP[T]['request']
}) {
    const url = endpoint
    const queryKey = generateQueryKey(endpoint, payload)
    const result = useSuspenseQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey,
        queryFn: () => get(endpoint, url, payload),
    })

    return result
}

export function useInvalidate() {
    const queryClient = useQueryClient()

    return <T extends keyof GET_MAP>(
        endpoint: T,
        ...args: GET_MAP[T]['request'] extends undefined ? [] : [GET_MAP[T]['request']]
    ) => {
        queryClient.invalidateQueries({
            queryKey: generateQueryKey(endpoint, args[0]),
        })
    }
}

export function usePost<T extends keyof POST_MAP>(endpoint: T) {
    const url = endpoint

    const result = useMutation<POST_MAP[T]['response'], unknown, POST_MAP[T]['request'], unknown>({
        mutationFn: data => post(endpoint, url, data),
    })

    return result
}
