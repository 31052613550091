import {ChevronLeft} from 'lucide-react'
import {Button} from './ui/button'
import {useNavigate} from 'react-router-dom'

export const PageBack = () => {
    const navigate = useNavigate()
    return (
        <div className="mb-8 md:mb-24">
            <Button onClick={() => navigate(-1)} variant="outline">
                <ChevronLeft />
            </Button>
        </div>
    )
}
