import {type ClassValue, clsx} from 'clsx'
import Decimal from 'decimal.js'
import {MutableRefObject, useEffect, useState} from 'react'
import {twMerge} from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function useSize<T extends HTMLElement>(
    ref: MutableRefObject<T | null>,
    defaultHeight = 400,
    defaultWidth = 80,
) {
    const [size, setSize] = useState<{width: number; height: number}>({
        width: defaultWidth,
        height: defaultHeight,
    })

    useEffect(() => {
        if (!ref.current) {
            setSize({width: defaultWidth, height: defaultHeight})
            return
        }
        const element = ref.current
        // provide size as early as possible
        setSize({width: element.offsetWidth, height: element.offsetHeight})

        const resizeObserver = new ResizeObserver(entries => {
            if (!Array.isArray(entries)) {
                return
            }

            // Since we only observe the one element, we don't need to loop over the
            // array
            if (!entries.length) {
                return
            }

            const entry = entries[0]
            let width: number
            let height: number

            if ('borderBoxSize' in entry) {
                const borderSizeEntry = entry['borderBoxSize']
                // iron out differences between browsers
                const borderSize: ResizeObserverSize = Array.isArray(borderSizeEntry)
                    ? borderSizeEntry[0]
                    : borderSizeEntry
                width = borderSize['inlineSize']
                height = borderSize['blockSize']
            } else {
                // for browsers that don't support `borderBoxSize`
                // we calculate it ourselves to get the correct border box.
                width = element.offsetWidth
                height = element.offsetHeight
            }

            setSize({width, height})
        })

        resizeObserver.observe(element, {box: 'border-box'})

        return () => resizeObserver.unobserve(element)
    }, [defaultWidth, defaultHeight, ref])

    return size
}

export const formatLargeNumber = (value: number | string) => {
    const decimal = new Decimal(value)
    const suffixes = ['', 'K', 'M', 'B', 'T']

    const suffixIdx = Math.max(
        0,
        Math.min(
            suffixes.length - 1,
            Number(Math.floor(decimal.eq(0) ? 0 : Math.log10(decimal.abs().toNumber()) / 3)),
        ),
    )

    const decimalAtMagnitude = decimal.div(new Decimal(10).pow(3 * suffixIdx))
    return `${decimalAtMagnitude.toDP(2).toString()}${suffixes[suffixIdx]}`
}

export function filterNonNullable<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined
}

export function sum(values: number[]) {
    return values.reduce((acc, curr) => acc + curr, 0)
}

export function clamp({min, value, max}: {min?: number; value: number; max?: number}) {
    return Math.min(Math.max(min ?? value, value), max ?? value)
}
