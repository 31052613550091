import React from 'react'
import ReactDOM from 'react-dom/client'
import {AppRoot} from './AppRoot'
import posthog from 'posthog-js'
import {PostHogProvider} from 'posthog-js/react'

posthog.init(_POSTHOG_API_KEY_, {
    api_host: 'https://hypertunnel.greenchipdata.com',
    person_profiles: 'always',
})

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <PostHogProvider client={posthog}>
            <AppRoot />
        </PostHogProvider>
    </React.StrictMode>,
)
