import React from 'react'
import {Helmet} from 'react-helmet-async'
import {Navigate, useNavigate} from 'react-router-dom'
import {useInvalidate, usePost} from '~/api/query'
import {Button} from '~/components/ui/button'
import {Formik} from 'formik'
import {Form, FormInput, FormStatus} from '~/components/ui/form'
import {toFormikValidate} from 'zod-formik-adapter'
import {z} from 'zod'
import {Card} from '~/components/ui/card'
import {PageBack} from '~/components/PageBack'
import {buildUrl} from '~/router'
import {useProfile} from '~/hooks/useProfile'

const SignupSchema = z
    .object({
        email: z.string(),
        password: z.string(),
        confirm_password: z.string(),
    })
    .superRefine(({password, confirm_password}, ctx) => {
        if (password !== confirm_password) {
            ctx.addIssue({
                code: 'custom',
                message: 'Passwords do not match',
                path: ['confirm_password'],
            })
        }
    })

const Signup: React.FC = () => {
    const navigate = useNavigate()
    const signupMutation = usePost('/identity/signup')
    const profile = useProfile()
    const invalidateQuery = useInvalidate()

    if (profile.type == 'user') {
        return <Navigate to={buildUrl('/')} replace />
    }

    return (
        <div className="flex flex-1 flex-col">
            <Helmet>
                <title>Sign Up | Green Chip Data</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <PageBack />
            <Card className="mx-auto w-96 p-4">
                <div className="flex-1 items-center justify-center">
                    <h2>Sign Up</h2>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            confirm_password: '',
                        }}
                        validate={toFormikValidate(SignupSchema)}
                        onSubmit={async (values, {setSubmitting, setStatus}) => {
                            setSubmitting(true)
                            const response = await signupMutation.mutateAsync({
                                email: values.email,
                                password: values.password,
                            })
                            setSubmitting(false)
                            if (response.type === 'user') {
                                invalidateQuery('/identity/profile')
                                navigate(buildUrl('/'))
                            } else if (response.type === 'identity_error') {
                                const codeToMessage: Record<typeof response.code, string> = {
                                    already_authenticated: 'You are already authenticated',
                                    invalid_credentials: 'Invalid username or password',
                                    user_already_exists: 'That email is already registered',
                                }

                                setStatus(codeToMessage[response.code])
                            }
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form>
                                <FormInput name="email" label="Email" type="email" />
                                <FormInput name="password" label="Password" type="password" />
                                <FormInput
                                    name="confirm_password"
                                    label="Confirm password"
                                    type="password"
                                />
                                <FormStatus />
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="self-center"
                                >
                                    Submit
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Card>
        </div>
    )
}

export default Signup
