import * as React from 'react'
import {Link} from 'react-router-dom'

import {cn} from '~/lib/utils'

const Table = React.forwardRef<
    HTMLTableElement,
    React.HTMLAttributes<HTMLTableElement> & {overflow?: 'unset'}
>(({className, overflow, ...props}, ref) => (
    <div className={cn('relative w-full', overflow !== 'unset' && 'overflow-auto')}>
        <table ref={ref} className={cn('w-full caption-bottom rounded', className)} {...props} />
    </div>
))
Table.displayName = 'Table'

const TableHeader = React.forwardRef<
    HTMLTableSectionElement,
    React.HTMLAttributes<HTMLTableSectionElement>
>(({className, ...props}, ref) => (
    <thead ref={ref} className={cn('[&_tr]:border-b', className)} {...props} />
))
TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef<
    HTMLTableSectionElement,
    React.HTMLAttributes<HTMLTableSectionElement>
>(({className, ...props}, ref) => (
    <tbody ref={ref} className={cn('[&_tr:last-child]:border-0', className)} {...props} />
))
TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef<
    HTMLTableSectionElement,
    React.HTMLAttributes<HTMLTableSectionElement>
>(({className, ...props}, ref) => (
    <tfoot
        ref={ref}
        className={cn('bg-muted/50 border-t font-medium [&>tr]:last:border-b-0', className)}
        {...props}
    />
))
TableFooter.displayName = 'TableFooter'

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
    ({className, ...props}, ref) => (
        <tr
            ref={ref}
            className={cn(
                'hover:bg-muted/50 data-[state=selected]:bg-muted border-b transition-colors first:rounded-t last:rounded-b',
                className,
            )}
            {...props}
        />
    ),
)
TableRow.displayName = 'TableRow'

const TableHead = React.forwardRef<
    HTMLTableCellElement,
    React.ThHTMLAttributes<HTMLTableCellElement>
>(({className, ...props}, ref) => (
    <th
        ref={ref}
        className={cn(
            'bg-muted-foreground text-muted h-10 px-2 text-left align-middle font-medium first:rounded-tl last:rounded-tr [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
            className,
        )}
        {...props}
    />
))
TableHead.displayName = 'TableHead'

const TableCell = React.forwardRef<
    HTMLTableCellElement,
    React.TdHTMLAttributes<HTMLTableCellElement> & {cellHref?: string}
>(({className, cellHref, ...props}, ref) => {
    if (cellHref) {
        const {children, ...otherProps} = props
        return (
            <td
                ref={ref}
                className={cn(
                    'p-0 [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
                    className,
                )}
                {...otherProps}
            >
                <Link className="block p-2 align-middle" to={cellHref}>
                    {children}
                </Link>
            </td>
        )
    }
    return (
        <td
            ref={ref}
            className={cn(
                'p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
                className,
            )}
            {...props}
        />
    )
})
TableCell.displayName = 'TableCell'

export {Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell}
