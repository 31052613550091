import StocksTable from '~/components/StocksTable'
import {Helmet} from 'react-helmet-async'
import {useGet} from '~/api/query'
import {PageBack} from '~/components/PageBack'

function AllSecurities() {
    const {data} = useGet({endpoint: '/securities/'})

    return (
        <>
            <Helmet>
                <title>All Stocks | Green Chip Data</title>
            </Helmet>
            <PageBack />
            <h2 className="text-center">All Stocks</h2>
            <StocksTable response={data} />
        </>
    )
}

export default AllSecurities
