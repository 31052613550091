import {ColumnDef} from '@tanstack/react-table'
import {formatLargeNumber} from '~/lib/utils'
import {Listing, SecuritiesGetListingsResponseListings} from '~/api/types'
import {DataTable, DataTableSortableColumnHeader} from '~/components/ui/dataTable'
import SecurityImage from '~/components/ui/securityImage'
import {buildUrl} from '~/router'

const columns: ColumnDef<Listing>[] = [
    {
        accessorKey: 'icon_url',
        header: '',
        cell: ({row}) => {
            const imgUrl = row.getValue<string | undefined>('icon_url')
            const name = row.getValue<string | undefined>('name')

            if (imgUrl && name) {
                return (
                    <SecurityImage
                        imgUrl={imgUrl}
                        alt={name}
                        className="h-8 w-8 min-w-8 border-none shadow-sm"
                    />
                )
            }

            return <div className="h-8 w-8 min-w-8" />
        },
    },
    {
        accessorKey: 'symbol',
        header: 'Symbol',
    },
    {
        accessorKey: 'name',
        header: 'Name',
    },
    {
        accessorKey: 'market_cap',
        header: ({column}) => (
            <DataTableSortableColumnHeader column={column} title="Market Cap" className="ml-auto" />
        ),
        cell: ({row}) => <>{formatLargeNumber(row.getValue('market_cap'))}</>,
    },
]

function StocksTable({response}: {response: SecuritiesGetListingsResponseListings}) {
    return (
        <DataTable
            columns={columns}
            data={response.listings}
            rowHref={row => buildUrl(':symbol', {symbol: row.symbol})}
        />
    )
}

export default StocksTable
