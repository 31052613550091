import {ColumnDef} from '@tanstack/react-table'
import {ChevronRight} from 'lucide-react'
import {SecuritiesUpcomingEarningsResponseUpcomingEarnings} from '~/api/types'
import {DataTable} from '~/components/ui/dataTable'
import {buildUrl} from '~/router'

const columns: ColumnDef<SecuritiesUpcomingEarningsResponseUpcomingEarnings['earnings'][number]>[] =
    [
        {
            accessorKey: 'security_symbol',
            header: 'Stock',
        },
        {
            accessorKey: 'release_date',
            header: 'Date',
            cell: ({row}) => new Date(row.original.release_date).toLocaleDateString(),
        },
        {
            header: 'link',
            cell: () => <ChevronRight className="w-4" />,
        },
    ]

function EarningsTable({response}: {response: SecuritiesUpcomingEarningsResponseUpcomingEarnings}) {
    return (
        <>
            <h3 className="text-center">Earnings Calendar</h3>
            <DataTable
                className="mx-auto max-w-64"
                columns={columns}
                data={response.earnings}
                rowHref={row => buildUrl(':symbol', {symbol: row.security_symbol})}
                hideTableHeader
            />
        </>
    )
}

export {EarningsTable}
