import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
    Column,
    SortingState,
    getSortedRowModel,
} from '@tanstack/react-table'
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '~/components/ui/table'
import {Button} from './button'
import {cn} from '~/lib/utils'
import {ArrowDownIcon, ArrowUpIcon} from 'lucide-react'
import {useState} from 'react'

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
    className?: string
    rowHref?: (row: TData) => string
    hideTableHeader?: boolean
}

export function DataTable<TData, TValue>({
    columns,
    data,
    className,
    rowHref,
    hideTableHeader,
}: DataTableProps<TData, TValue>) {
    const [sorting, setSorting] = useState<SortingState>([])
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: {
            sorting,
        },
    })

    return (
        <div className={cn('rounded-md border', className)}>
            <Table className="bg-card" overflow="unset">
                <TableHeader className={cn(hideTableHeader && 'hidden')}>
                    {table.getHeaderGroups().map(headerGroup => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <TableHead key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext(),
                                              )}
                                    </TableHead>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map(row => (
                            <TableRow
                                key={row.id}
                                data-state={(row.getIsSelected() && 'selected') || undefined}
                            >
                                {row.getVisibleCells().map(cell => (
                                    <TableCell
                                        key={cell.id}
                                        cellHref={rowHref ? rowHref(row.original) : undefined}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                                No results.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}

interface DataTableSortableColumnHeaderProps<TData, TValue>
    extends React.HTMLAttributes<HTMLDivElement> {
    column: Column<TData, TValue>
    title: string
}

export function DataTableSortableColumnHeader<TData, TValue>({
    column,
    title,
    className,
}: DataTableSortableColumnHeaderProps<TData, TValue>) {
    if (!column.getCanSort()) {
        return <div className={cn(className)}>{title}</div>
    }

    const currentSorting = column.getIsSorted()

    return (
        <div className={cn('flex items-center space-x-2', className)}>
            <Button
                variant="ghost"
                className="-ml-3 h-8"
                onClick={() => {
                    return currentSorting == 'desc'
                        ? column.toggleSorting(false)
                        : currentSorting == 'asc'
                          ? column.clearSorting()
                          : column.toggleSorting(true)
                }}
            >
                <span>{title}</span>
                {currentSorting === 'desc' ? (
                    <ArrowDownIcon className="ml-2 h-4 w-4" />
                ) : currentSorting === 'asc' ? (
                    <ArrowUpIcon className="ml-2 h-4 w-4" />
                ) : null}
            </Button>
        </div>
    )
}
