import {APIError} from '~/api/query'
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from './ui/card'
import {Button} from './ui/button'
import {useNavigate} from 'react-router-dom'

export default function ErrorPage({
    error,
    errorText,
    resetErrorBoundary,
}: {
    error?: unknown
    errorText?: string
    resetErrorBoundary?: () => void
}) {
    const navigate = useNavigate()
    return (
        <div className="flex h-full w-full flex-col items-center justify-center">
            <Card>
                <CardHeader>
                    <CardTitle>An error occurred</CardTitle>
                </CardHeader>
                <CardContent>
                    {errorText && <p>{errorText}</p>}
                    {error instanceof APIError && (
                        <p>
                            <pre>{JSON.stringify(error)}</pre>
                        </p>
                    )}
                </CardContent>
                {error instanceof APIError && error.status == 404 ? (
                    <CardFooter>
                        <Button onClick={() => navigate(-1)} variant="default">
                            Go Back
                        </Button>
                    </CardFooter>
                ) : (
                    resetErrorBoundary && (
                        <CardFooter>
                            <Button onClick={resetErrorBoundary} variant="default">
                                Try again
                            </Button>
                        </CardFooter>
                    )
                )}
            </Card>
        </div>
    )
}
