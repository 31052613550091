import './globals.scss'
import styles from './GlobalWrapper.module.scss'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {SuspenseAndBoundary} from './components/SuspenseAndBoundary'
import {BarChart4, Layers, Home, User, Menu} from 'lucide-react'
import DarkModeSelector from './components/DarkModeSelector'
import {useProfile} from './hooks/useProfile'
import {useInvalidate, usePost} from './api/query'
import {Button} from './components/ui/button'
import {buildUrl} from './router'
import {DropdownLinks} from './components/ui/dropdown-menu'

const SideNav: React.FC = () => {
    const profile = useProfile()
    const navigate = useNavigate()
    const invalidateQuery = useInvalidate()
    const logoutMutation = usePost('/identity/logout')
    const location = useLocation()

    const doLogout = async () => {
        await logoutMutation.mutateAsync({})
        invalidateQuery('/identity/profile')
        navigate(buildUrl('/'))
    }

    const items = [
        {label: 'Home', href: buildUrl('/'), icon: Home},
        {label: 'All Stocks', href: buildUrl('all-securities'), icon: Layers},
        ...['AAPL', 'NVDA', 'MSFT', 'V'].map(symbol => ({
            label: symbol,
            href: buildUrl(':symbol', {symbol}),
            icon: BarChart4,
        })),
    ]

    const renderStockLinks = () => {
        return (
            <>
                <div className={styles.mobileLinks}>
                    <DropdownLinks
                        className="dark"
                        links={items.map(item => ({
                            label: item.label,
                            href: item.href,
                            isActive: location.pathname == item.href,
                        }))}
                    >
                        <Menu />
                    </DropdownLinks>
                </div>
                <div className={styles.desktopLinks}>
                    {items.map((item, i) => (
                        <Link key={i} className={styles.navButton} to={item.href}>
                            <div className="hidden w-4 text-sm md:block">
                                <item.icon width={16} />
                            </div>
                            {item.label}
                        </Link>
                    ))}
                </div>
            </>
        )
    }

    return (
        <div className={styles.sideNav}>
            <div className={styles.sideNavInner}>
                <h2 className="border-muted mb-0 block justify-self-stretch py-3 pr-4 md:my-0 md:mb-2 md:w-full md:border-b-2 md:py-6 md:pr-0">
                    <Link to={buildUrl('/')}>
                        <span className="sr-only">Home</span>
                        <div className="flex items-center justify-center gap-4 md:flex-col">
                            <img
                                src="/logo-eb62ce3f.svg"
                                className="mx-auto block min-h-12 w-16 md:w-40"
                                alt="Green Chip Data"
                            />
                            <span className="text-center text-sm font-medium md:text-base">
                                Green Chip Data
                            </span>
                        </div>
                    </Link>
                </h2>
                {renderStockLinks()}
                {profile.type === 'user' && (
                    <>
                        <Link className={styles.navButton} to={buildUrl('admin')}>
                            <div className="block w-4 text-sm">
                                <User width={16} />
                            </div>
                            <span className="hidden md:block">{profile.email}</span>
                        </Link>
                        <Button
                            className={styles.navButton}
                            variant="ghost"
                            onClick={() => {
                                doLogout()
                            }}
                        >
                            Logout
                        </Button>
                    </>
                )}
                <div className="flex items-center justify-center md:p-2">
                    <DarkModeSelector />
                </div>
            </div>
        </div>
    )
}

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className="flex justify-center">
                <Link to={buildUrl('disclaimer')}>Disclaimer</Link>
            </div>
        </footer>
    )
}

function GlobalWrapper({children}: {children: React.ReactNode}) {
    return (
        <div className={styles.pageWrap}>
            <SideNav />
            <div className={styles.page}>
                <SuspenseAndBoundary>
                    {children}
                    <Footer />
                </SuspenseAndBoundary>
            </div>
        </div>
    )
}

export default GlobalWrapper
