import {QueryErrorResetBoundary} from '@tanstack/react-query'
import {ErrorInfo, Suspense} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import Loading from './Loading'
import {useLocation} from 'react-router-dom'
import ErrorPage from './ErrorPage'

export const SuspenseAndBoundary: React.FC<{children: React.ReactNode}> = ({children}) => {
    const location = useLocation()

    const onError = (_error: Error, _info: ErrorInfo) => {
        // TODO: Send error to sentry
    }

    return (
        <QueryErrorResetBoundary>
            {({reset}) => (
                <ErrorBoundary
                    key={location.pathname}
                    onReset={reset}
                    onError={onError}
                    fallbackRender={({resetErrorBoundary, error}) => (
                        <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />
                    )}
                >
                    <Suspense fallback={<Loading />}>{children}</Suspense>
                </ErrorBoundary>
            )}
        </QueryErrorResetBoundary>
    )
}
