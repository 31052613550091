import {ArrowRight, ChevronRight} from 'lucide-react'
import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Button} from '~/components/ui/button'
import {Input} from '~/components/ui/input'
import {useGet} from '~/api/query'
import {Popover, PopoverAnchor, PopoverContent, PopoverTrigger} from '~/components/ui/popover'
import {Table, TableBody, TableCell, TableRow} from '~/components/ui/table'
import {useMiniSearch} from 'react-minisearch'
import MiniSearch from 'minisearch'
import SecurityImage from '~/components/ui/securityImage'
import posthog from 'posthog-js'
import {Helmet} from 'react-helmet-async'
import {buildUrl} from '~/router'
import StocksTable from '~/components/StocksTable'
import {
    SecuritiesGetListingsResponseListings,
    SecuritiesUpcomingEarningsResponseUpcomingEarnings,
} from '~/api/types'
import {EarningsTable} from '~/components/EarningsTable'

function SearchAndNavigate({onSubmit}: {onSubmit: (symbol: string) => void}) {
    const {data: results} = useGet({
        endpoint: '/securities/search-securities',
    })
    const [searchTerm, setSearchTerm] = useState('')

    const {search, searchResults} = useMiniSearch<(typeof results.listings)[number]>(
        results.listings,
        {
            fields: ['symbol', 'name', 'exchange'],
            searchOptions: {
                tokenize: val => MiniSearch.getDefault('tokenize')(val.toLowerCase()),
                processTerm: term => term.toLowerCase(),
            },
        },
    )

    return (
        <form
            className="relative flex w-full max-w-md gap-3"
            onSubmit={e => {
                e.preventDefault()
                if (searchResults && searchResults.length > 0) {
                    onSubmit(searchResults[0].symbol)
                }
            }}
        >
            <Popover>
                <PopoverAnchor />
                <PopoverTrigger autoFocus={false} tabIndex={-1} asChild>
                    <Input
                        type="text"
                        placeholder="Search... AAPL, V, NVDA"
                        className="max-w-60"
                        variant="lg"
                        rounded="circle"
                        value={searchTerm}
                        onChange={e => {
                            setSearchTerm(e.target.value)
                            search(e.target.value)
                        }}
                    />
                </PopoverTrigger>
                {searchResults && searchResults.length > 0 && (
                    <PopoverContent
                        className="left-0 min-h-14 p-0"
                        align="start"
                        autoFocus={false}
                        onOpenAutoFocus={e => e.preventDefault()}
                    >
                        <Table overflow="unset">
                            <TableBody>
                                {(searchResults ?? []).slice(0, 10).map((listing, index) => (
                                    <TableRow
                                        key={index}
                                        className="flex justify-stretch last:border-b-0"
                                        onClick={() => onSubmit(listing.symbol)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <TableCell>
                                            {!!listing.icon_url && !!listing.name ? (
                                                <SecurityImage
                                                    imgUrl={listing.icon_url}
                                                    alt={listing.name}
                                                    className="h-6 w-6"
                                                />
                                            ) : (
                                                <div className="h-6 w-6" />
                                            )}
                                        </TableCell>
                                        <TableCell>{listing.symbol}</TableCell>
                                        <TableCell>{listing.name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </PopoverContent>
                )}
            </Popover>
            <Button type="submit" disabled={!searchResults?.length} size="lg" variant="muted">
                Go <ArrowRight className="ml-1" width={16} />
            </Button>
        </form>
    )
}

const StocksSection = ({securities}: {securities: SecuritiesGetListingsResponseListings}) => {
    return (
        <div>
            <StocksTable response={securities} />
            <div className="mt-8 flex items-center justify-center gap-4">
                <Button asChild>
                    <Link to={buildUrl('all-securities')}>
                        View More <ChevronRight />
                    </Link>
                </Button>
            </div>
        </div>
    )
}

const EarningsSection = ({
    earnings,
}: {
    earnings: SecuritiesUpcomingEarningsResponseUpcomingEarnings
}) => {
    return (
        <>
            <EarningsTable response={earnings} />
        </>
    )
}

function Index() {
    const navigate = useNavigate()
    const {data: securities} = useGet({endpoint: '/securities/', payload: {limit: 10}})
    const {data: earnings} = useGet({endpoint: '/securities/upcoming-earnings'})

    const onSearch = (symbol: string) => {
        posthog.capture('search_submitted', {symbol})
        navigate(buildUrl(':symbol', {symbol: symbol}))
    }

    return (
        <>
            <Helmet>
                <title>Green Chip: Explore Popular Stocks with Charts | Home</title>
            </Helmet>
            <div className="flex h-full flex-col items-center justify-center pb-24 md:max-h-[calc(100vh/2)]">
                <div className="my-8 block">
                    <img
                        src="/logo-eb62ce3f.svg"
                        className="mb-6 block max-h-24 w-full"
                        alt="Green Chip Data"
                    />
                    <p className="text-muted-foreground text-center">Green Chip Data</p>
                </div>
                <h2 className="mb-12 text-2xl font-medium md:text-3xl">
                    Stock research, at 10x speed
                </h2>
                <SearchAndNavigate onSubmit={onSearch} />
            </div>
            {earnings.earnings.length > 0 ? (
                <div className="grid grid-cols-1 gap-x-4 gap-y-20 md:grid-cols-3">
                    <div className="col-span-2">
                        <StocksSection securities={securities} />
                    </div>
                    <div>
                        <EarningsSection earnings={earnings} />
                    </div>
                </div>
            ) : (
                <StocksSection securities={securities} />
            )}
        </>
    )
}

export default Index
