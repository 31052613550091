import {Helmet} from 'react-helmet-async'
import {SuspenseAndBoundary} from './components/SuspenseAndBoundary'
import {Link, Navigate, Outlet} from 'react-router-dom'
import styles from './GlobalWrapper.module.scss'
import {cn} from './lib/utils'
import {useProfile} from './hooks/useProfile'
import {buildUrl} from './router'
import {Home} from 'lucide-react'

export default function AdminRoot() {
    const profile = useProfile()

    if (profile.type === 'anonymous') {
        return <Navigate to={buildUrl('/')} replace />
    }

    return (
        <div className={cn(styles.pageWrap, styles.admin)}>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className={styles.adminNav}>
                <div className={styles.adminNavInner}>
                    <h2 className="text-accent-foreground mb-0">
                        <Link to={buildUrl('admin')}>Admin</Link>
                    </h2>
                    <div className={styles.links}>
                        <Link className={styles.adminNavButton} to={buildUrl('admin/benchmark')}>
                            Benchmarks
                        </Link>
                        <div className="flex-1"></div>
                        <Link className={styles.adminNavButton} to={buildUrl('/')}>
                            <Home /> Back Home
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.page}>
                <SuspenseAndBoundary>
                    <Outlet />
                </SuspenseAndBoundary>
            </div>
        </div>
    )
}
