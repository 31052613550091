import React from 'react'
import {useEffect, useState} from 'react'
import {Eclipse, MoonIcon, SunIcon} from 'lucide-react'
import {Button} from './ui/button'

type Theme = 'system' | 'dark' | 'light'

const DarkModeSelector: React.FC = () => {
    const [theme, setTheme] = useState<Theme>(
        (localStorage.getItem('darkMode') as Theme) ?? 'system',
    )

    useEffect(() => {
        if (theme === 'system') {
            localStorage.removeItem('darkMode')
        } else {
            localStorage.setItem('darkMode', theme)
        }
    }, [theme])

    useEffect(() => {
        window.document.body.classList.remove('darkMode')
        window.document.body.classList.remove('lightMode')
        if (theme === 'dark') {
            window.document.body.classList.add('darkMode')
        } else if (theme === 'light') {
            window.document.body.classList.add('lightMode')
        }
    }, [theme])

    return (
        <Button
            onClick={() =>
                setTheme(theme === 'system' ? 'dark' : theme === 'dark' ? 'light' : 'system')
            }
            variant="ghost"
        >
            {theme === 'dark' ? (
                <MoonIcon className="h-4 w-4" />
            ) : theme === 'light' ? (
                <SunIcon className="h-4 w-4" />
            ) : (
                <Eclipse className="h-4 w-4" />
            )}
            <span className="sr-only">Toggle dark mode</span>
        </Button>
    )
}

export default DarkModeSelector
