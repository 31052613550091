import {VariantProps, cva} from 'class-variance-authority'
import * as React from 'react'

import {cn} from '~/lib/utils'

const inputVariants = cva(
    'border-input placeholder:text-muted-foreground flex w-full border bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:font-medium disabled:cursor-not-allowed disabled:opacity-50',
    {
        variants: {
            variant: {
                default: 'h-9 text-sm px-3 py-1 file:text-sm',
                lg: 'h-10 text-md px-3 py-1 file:text-md',
            },
            rounded: {
                default: 'rounded-md',
                circle: 'rounded-full',
            },
        },
        defaultVariants: {
            variant: 'default',
            rounded: 'default',
        },
    },
)

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement>,
        VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({className, type, variant, rounded, ...props}, ref) => {
        return (
            <input
                type={type}
                className={cn(inputVariants({variant, rounded}), className)}
                ref={ref}
                {...props}
            />
        )
    },
)
Input.displayName = 'Input'

export {Input}
