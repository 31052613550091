import React from 'react'
import {cn} from '~/lib/utils'
import {Label} from '~/components/ui/label'
import {useField, useFormikContext} from 'formik'
import {Input} from './input'

const Form: React.FC<{children: React.ReactNode; className?: string}> = ({children, className}) => {
    const {handleSubmit} = useFormikContext()

    return (
        <form
            onSubmit={handleSubmit}
            className={cn('flex flex-col items-stretch space-y-6', className)}
        >
            {children}
        </form>
    )
}

interface FormInputProps {
    label?: string
    name: string
    type?: React.HTMLInputTypeAttribute
    helpText?: string
    placeholder?: string
    className?: string
}
const FormInput: React.FC<FormInputProps> = ({
    name,
    label,
    type = 'text',
    helpText,
    placeholder,
}) => {
    const [{onChange, onBlur}, {value, error, touched}] = useField({name})

    const itemId = `${name}-form-id`
    const descriptionId = `${name}-form-description`
    const errorId = `${name}-form-error`

    return (
        <div className="space-y-2">
            {label && (
                <Label htmlFor={itemId} className={cn(error && touched && 'text-destructive')}>
                    {label}
                </Label>
            )}
            <Input
                name={name}
                id={itemId}
                type={type}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                aria-describedby={!error ? `${descriptionId}` : `${descriptionId} ${errorId}`}
                aria-invalid={!!error}
            />
            {helpText && (
                <p id={descriptionId} className="text-muted-foreground text-[0.8rem]">
                    {helpText}
                </p>
            )}
            {error && touched && (
                <p id={errorId} className="text-destructive text-[0.8rem] font-medium">
                    {error}
                </p>
            )}
        </div>
    )
}

const FormStatus: React.FC<{className?: string}> = ({className}) => {
    const {status} = useFormikContext()

    if (!status) {
        return null
    }

    return (
        <p id="form-status" className={cn(className, 'text-destructive')}>
            {status}
        </p>
    )
}

export {Form, FormInput, FormStatus}
