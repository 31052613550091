import {Helmet} from 'react-helmet-async'
import {PageBack} from '~/components/PageBack'
import styles from './Disclaimer.module.scss'

export default function Disclaimer() {
    return (
        <>
            <PageBack />
            <Helmet>
                <title>Disclaimer | Green Chip Data</title>
            </Helmet>
            <h1>Disclaimer</h1>
            <p>
                Green Chip Data is designed to educate and inform, but it’s not a substitute for
                professional financial advice. While we strive to provide accurate and up-to-date
                information, we cannot guarantee the reliability of the data presented on this
                platform.
            </p>

            <p>
                Always take the time to do your own research and consult trusted sources before
                making financial decisions. Green Chip Data does not provide advice to buy or sell
                securities. The responsibility for your investment choices rests solely with you.
            </p>
            <p>
                If you have any questions, please{' '}
                <a className={styles.link} href="mailto:hello@greenchipdata.com">
                    contact us
                </a>
                .
            </p>
            <h2 className="mt-10">Data Source</h2>
            <p>
                Green Chip Data sources financial data from SEC filings, specifically 10-Q and 10-K
                reports.
            </p>
            <p>
                Data points from annual and quarterly reports differ across the relevant periods;
                quarterly reports typically cover a 3-month period, while annual reports cover a
                12-month period. To account for this, data points from annual reports are derived by
                subtracting the three-month data from the full-year figures.
            </p>
            <p>
                While company filings generally adhere to GAAP metrics, companies are allowed to
                report custom metrics, which can lead to variations between filings. To address
                this, the platform applies normalization to ensure consistent comparisons across
                reports.
            </p>
        </>
    )
}
