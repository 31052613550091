import React from 'react'
import {Helmet} from 'react-helmet-async'
import {Navigate, useNavigate} from 'react-router-dom'
import {useInvalidate, usePost} from '~/api/query'
import {Button} from '~/components/ui/button'
import {Formik} from 'formik'
import {Form, FormInput, FormStatus} from '~/components/ui/form'
import {toFormikValidate} from 'zod-formik-adapter'
import {z} from 'zod'
import {Card} from '~/components/ui/card'
import {PageBack} from '~/components/PageBack'
import {buildUrl} from '~/router'
import {useProfile} from '~/hooks/useProfile'

const LoginSchema = z.object({
    email: z.string(),
    password: z.string(),
})

const Login: React.FC = () => {
    const navigate = useNavigate()
    const invalidateQuery = useInvalidate()
    const profile = useProfile()
    const loginMutation = usePost('/identity/login')

    if (profile.type == 'user') {
        return <Navigate to={buildUrl('/')} replace />
    }

    return (
        <div className="flex flex-1 flex-col">
            <Helmet>
                <title>Login | Green Chip Data</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <PageBack />
            <Card className="mx-auto w-96 p-4">
                <div className="flex-1 items-center justify-center">
                    <h2>Login</h2>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validate={toFormikValidate(LoginSchema)}
                        onSubmit={async (values, {setSubmitting, setStatus}) => {
                            setSubmitting(true)
                            const response = await loginMutation.mutateAsync({
                                email: values.email,
                                password: values.password,
                            })
                            setSubmitting(false)
                            if (response.type === 'user') {
                                invalidateQuery('/identity/profile')
                                navigate(buildUrl('/'))
                            } else if (response.type === 'login_error') {
                                const codeToMessage: Record<typeof response.code, string> = {
                                    already_authenticated: 'You are already authenticated',
                                    invalid_credentials: 'Invalid username or password',
                                }

                                setStatus(codeToMessage[response.code])
                            }
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form>
                                <FormInput name="email" label="Email" type="email" />
                                <FormInput name="password" label="Password" type="password" />
                                <FormStatus />
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="self-center"
                                >
                                    Login
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Card>
        </div>
    )
}

export default Login
